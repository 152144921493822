@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Work Sans';
  src:
    local('Work Sans'),
    url('./assets/fonts/Work_Sans/WorkSans-VariableFont_wght.ttf') format('truetype');
  font-weight: 1 1000;
}

@font-face {
  font-family: 'Work Sans Italic';
  src:
    local('Work Sans'),
    url('./assets/fonts/Work_Sans/WorkSans-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 1 1000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.font-work-sans {
  font-family: 'Work Sans';
}

body {
  font-family: 'Work Sans';
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@layer base {

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

.payment-field {
  border-radius: 10px;
  /* height: 50px; */
  /* background-color: bisque; */
  font-size: 16px;
  transition: 200ms;
}

.payment-field input {
  width: 100%;
  padding: 10px 15px;
}

* {
  scrollbar-width: thin;
  /* Firefox - makes scrollbar thinner */
  scrollbar-color: #ACACAC #f5f5f5;
  /* Firefox - Thumb and track colors */
}

/* For Chrome, Safari, and Edge */
*::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
}

*::-webkit-scrollbar-track {
  background: #f5f5f5;
  /* Track (background part) color */
  border-radius: 12px;
  /* Rounded scrollbar track */
}

*::-webkit-scrollbar-thumb {
  background-color: #0185ff;
  /* Thumb color */
  border-radius: 12px;
  /* Rounded scrollbar thumb */
  border: 3px solid #f5f5f5;
  /* Adds padding around thumb */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #016fcc;
  /* Slightly darker thumb color on hover */
}

/* Optional: Hide scrollbar for mobile */
@media (max-width: 600px) {
  * {
    scrollbar-width: none;
    /* Hides scrollbar in Firefox */
  }

  *::-webkit-scrollbar {
    display: none;
    /* Hides scrollbar in Chrome/Safari */
  }
}